@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #95a5a6;
  border: 1px solid #cacaca;
}

html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #2c3e50;
}

body {
  padding: 0;
  margin: 0;
}

div.maplibregl-ctrl-bottom-right  {
  display: none;
}
